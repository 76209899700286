import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { HttpErrorResponse } from "@angular/common/http";

interface Errors {
  loading?: HttpErrorResponse | boolean;
  saving?: HttpErrorResponse | boolean;
  removing?: HttpErrorResponse | boolean;
}
export class ClearErrors {
  public static readonly type = "[Admin] clear errors";
  constructor(public payload?: {}) {}
}

export class SetError {
  public static readonly type = "[Admin] set errors";
  constructor(public error: Errors) {}
}

const clearAll: Errors = {
  loading: true,
  removing: true,
  saving: true
};

export class AdminPagesStateModel {
  loaded: boolean;
  loading: boolean;
  saving: boolean;
}

export const AdminPagesStateDefaults = {
  items: [],
  totalCount: 0,
  loaded: false,
  loading: false,
  saving: false
};

export class AdminStateModel {
  errors: Errors;
}

@State<AdminStateModel>({
  name: "admin",
  defaults: {
    errors: {
      loading: false,
      removing: false,
      saving: false
    }
  }
})
@Injectable()
export class AdminState {
  constructor() {}

  @Selector()
  static errors(state: AdminStateModel) {
    return state.errors;
  }

  @Action(SetError)
  setErrors(ctx: StateContext<AdminStateModel>, { error }) {
    const state = ctx.getState();
    ctx.patchState({
      errors: {
        ...state.errors,
        loading: error.loading || state.errors.loading,
        saving: error.saving || state.errors.saving,
        removing: error.removing || state.errors.removing
      }
    });
  }

  @Action(ClearErrors)
  clearErrors(ctx: StateContext<AdminStateModel>, { payload = clearAll }) {
    const state = ctx.getState();

    ctx.patchState({
      errors: {
        loading: payload.loading ? false : state.errors.loading,
        saving: payload.saving ? false : state.errors.saving,
        removing: payload.removing ? false : state.errors.removing
      }
    });
  }
}
